<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-model="record.tags" :module_name="page.name"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <base-table-includable ref="tasks_table" :page="page" :parent_module_id="record.id" relation_name="tasks" @create="createTask" @edit="editTask" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <base-create-dialog modal_name="create_task" ref="create_task" :page="tasks_page" @refresh="load"/>
    <base-edit-dialog modal_name="edit_task" ref="edit_task" :page="tasks_page" @refresh="load"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog";
import BaseCreateDialog from "../../components/commonComponents/BaseCreateDialog";

export default {
  components: {
    BaseCreateDialog,
    BaseEditDialog,
    BaseTableIncludable,
    BaseTabPanel,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.projects,
      tasks_page: helpFunctions.pages.tasks,
      record: {},
      usual_fields: [],
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.tasks_table.records = this.record.tasks;
      this.$refs.tasks_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createTask() {
      this.$refs.create_task.load();
      this.$refs.create_task.record.project_id = this.record.id;
    },
    editTask(item) {
      this.$refs.edit_task.load(item.id);
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
  },
};
</script>